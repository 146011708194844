import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { Grid } from 'shared/components/Grid';
import type { IColumnLayoutFields } from 'shared/infra/contentful/contentful';
import { ColumnLayout } from 'src/productSelection/containers/ColumnLayout';

export interface Props {
  sectionKey: string;
  sectionFields: IColumnLayoutFields;
}

const SectionsGrid = styled(Grid)<PropsWithChildren>`
  ${({ theme }) => css`
    padding: 0;
    width: 100%;
    margin-bottom: var(--cui-spacings-exa);
    margin-top: var(--cui-spacings-exa);

    ${theme.mq.mega} {
      margin-bottom: var(--cui-spacings-zetta);
    }
  `}
`;

export const Section: FC<Props> = ({ sectionKey, sectionFields }) => (
  <SectionsGrid>
    <ColumnLayout
      key={sectionKey}
      spanKey={sectionKey}
      columnLayoutFields={sectionFields}
    />
  </SectionsGrid>
);
