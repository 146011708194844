import { css, type Theme } from '@emotion/react';
import styled from '@emotion/styled';

export const GridWrapper = styled('ul')(
  ({ theme, cols }: { theme?: Theme; cols: number }) => css`
    display: grid;
    list-style: none;
    margin: 0;
    padding: 0 var(--cui-spacings-byte) var(--cui-spacings-zetta);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: var(--cui-spacings-mega);
    grid-row-gap: var(--cui-spacings-peta);

    ${theme.mq.mega} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      padding-bottom: calc(
        var(--cui-spacings-zetta) + var(--cui-spacings-zetta)
      );
    }

    ${theme.mq.tera} {
      grid-template-columns: repeat(${cols}, minmax(0, 1fr));
      grid-column-gap: var(--cui-spacings-giga);
    }
  `,
);

export const CardWrapper = styled('li')(
  () => css`
    position: relative;
    display: flex;
    flex-direction: column;
  `,
);
