import type { ParsedUrlQuery } from 'querystring';

import {
  getProductsWithAllDetails,
  type ProductWithPrices,
} from 'productSelection/services/product';
import type { DigitalProduct, Product } from 'productSelection/types/products';
import type { SkuPriceData } from 'shared/infra/storefront/markets/prices';
import { reorderCatalogByQuery } from 'shared/services/ProductService';

const TTPOI_AT_TOP_TRACKING_IDS = ['digital.tap-to-pay-on-iphone'];

export enum ProductCardSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
  xxl = 'xxl',
  xxxl = 'xxxl',
}

export const getProductsOnQuery = (query: ParsedUrlQuery): string[] => {
  const productsQueryParam = query.products || query.product;
  const productsOnQuery = Array.isArray(productsQueryParam)
    ? productsQueryParam
    : productsQueryParam?.split(',');

  return productsOnQuery;
};

export function hasTTPOIProduct(digitalProduct: DigitalProduct): boolean {
  return TTPOI_AT_TOP_TRACKING_IDS.includes(digitalProduct.trackingId);
}

export const patchOrderedProducts = (
  products: Product[],
  digitalProducts: DigitalProduct[],
  flagEnabled: boolean,
): {
  products: (Product | DigitalProduct)[];
  digitalProducts: DigitalProduct[];
} => {
  if (!flagEnabled) {
    return { products, digitalProducts };
  }

  const foundTTPOI = digitalProducts.filter(hasTTPOIProduct);

  if (!foundTTPOI.length) {
    return { products, digitalProducts };
  }

  const patchedProducts = [...foundTTPOI, ...products];

  const patchedDigitalProducts = digitalProducts.filter(
    (p) => !hasTTPOIProduct(p),
  );

  return {
    products: patchedProducts,
    digitalProducts: patchedDigitalProducts,
  };
};

export const getOrderedProducts = (
  products: Product[],
  digitalProducts: DigitalProduct[],
  prices: Record<string, SkuPriceData>,
  locale: string,
  query: ParsedUrlQuery,
  taxMessage = '',
  isTTPOIFlagEnabled = false,
): ProductWithPrices[] | Partial<ProductWithPrices>[] => {
  const { products: patchedProducts, digitalProducts: patchedDigitalProducts } =
    patchOrderedProducts(products, digitalProducts, isTTPOIFlagEnabled);

  const allProducts = [...patchedProducts, ...patchedDigitalProducts];
  const productsOnQuery = getProductsOnQuery(query);
  const orderedProductList = reorderCatalogByQuery(
    allProducts,
    productsOnQuery,
  );

  const productList = getProductsWithAllDetails(
    orderedProductList,
    prices,
    locale,
    taxMessage,
  );

  return productList;
};
