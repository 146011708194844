import type { ProductCardContent } from 'productSelection/types/content';
import { STORE_STORAGE_PREFIX } from 'shared/constants/FeatureFlags';
import type { IUiProductSelectionPageFields } from 'shared/infra/contentful/contentful';

export const getProductCardContent = (
  pageContent: IUiProductSelectionPageFields,
): ProductCardContent => ({
  callToActionText: pageContent.productCallToActionText,
  selectedCallToActionText: pageContent.productSelectedCallToActionText,
  moreInfoText: pageContent.productMoreInfoText,
  discountBadgeText: pageContent.productDiscountBadgeText,
  loadingText: pageContent.loadingText,
  freeBadgeText: pageContent.freeBadge,
  outOfStockText: pageContent.outOfStockText,
});

export const removeLocalMerchantSession = (): void => {
  sessionStorage.removeItem(`${STORE_STORAGE_PREFIX}oidc:token`);
  sessionStorage.removeItem('MERCHANT_PROFILE');
  sessionStorage.removeItem('PLACED_ORDER_INFO');
  sessionStorage.removeItem('PLACED_ORDER_PRODUCTS');
};
