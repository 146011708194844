import axios, { type AxiosRequestConfig } from 'axios';

import { STORE_STORAGE_PREFIX } from 'shared/constants/FeatureFlags';

import { buildHeaders } from '../api/services';
import { ENDPOINTS } from '../constants';

export interface TokenResponse {
  access_token: string;
  expires_in: number;
}

export interface TokenData {
  access_token: string;
  expires_at_in_seconds: number;
}

export const OIDC_GUEST_TOKEN = `${STORE_STORAGE_PREFIX}oidc:guest:token}`;

export const saveOIDCToken = (token: string, expireIn: number): void => {
  const toSaveToken: TokenData = {
    access_token: token,
    expires_at_in_seconds: Date.now() / 1000 + expireIn,
  };

  sessionStorage.setItem(OIDC_GUEST_TOKEN, JSON.stringify(toSaveToken));
};

export const fetchOIDCGuestToken = async (): Promise<TokenResponse | null> => {
  const tokenPayload = new URLSearchParams({
    grant_type: 'client_credentials',
    client_id: process.env.NEXT_PUBLIC_OIDC_CLIENT_CREDENTIALS_ID,
    client_secret: process.env.NEXT_PUBLIC_OIDC_CLIENT_CREDENTIALS_SECRET,
    scope: 'storefront',
  });

  try {
    const headers = buildHeaders(null, {
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const { data } = await axios.post<TokenResponse>(
      ENDPOINTS.OIDC,
      tokenPayload,
      {
        headers,
      },
    );

    return data;
  } catch (err) {
    console.error('fetching oidc token', err);

    return null;
  }
};

export const isStorageTokenValid = (token: TokenData): boolean =>
  token.access_token !== '' && token.expires_at_in_seconds * 1000 >= Date.now();

export const getOIDCGuestToken = async (): Promise<string> => {
  const tokenData = JSON.parse(
    sessionStorage.getItem(OIDC_GUEST_TOKEN),
  ) as TokenData;
  let guestToken;

  if (tokenData && isStorageTokenValid(tokenData)) {
    guestToken = tokenData;
  } else {
    guestToken = await fetchOIDCGuestToken();
    saveOIDCToken(guestToken.access_token, guestToken.expires_in);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return guestToken.access_token;
};

export const buildAuthedAxiosConf = async (): Promise<AxiosRequestConfig> => {
  const token = await fetchOIDCGuestToken();
  const axiosConf: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  };

  return axiosConf;
};
