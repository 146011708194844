import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Button, Headline, Image } from '@sumup/circuit-ui';
import { Col } from '@sumup/circuit-ui/legacy';
import type { FC } from 'react';

import type { IColumnFields } from 'shared/infra/contentful/contentful';
import { MERCHANT_ID } from 'shared/infra/cookies';
import { convertImageToPng } from 'shared/services/imageUrl';
import { dispatchSkipShopEvent } from 'shared/services/tracker/events';
import { useTypedSelector } from 'shared/store';

export interface Props {
  columnKey: string;
  columnFields: IColumnFields;
}

const StyledImageWrapper = styled('div')(
  ({ theme }) => css`
    background-color: var(--cui-bg-subtle);
    border-radius: var(--cui-border-radius-mega);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 206px;
    max-height: 340px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    margin-bottom: var(--cui-spacings-mega);
    margin-top: var(--cui-spacings-giga);

    img {
      max-height: 100%;
      max-width: 100%;
      overflow: hidden;
    }

    ${theme.mq.mega} {
      min-height: none;
      margin-bottom: var(--cui-spacings-giga);
      margin-top: var(--cui-spacings-zetta);
    }
  `,
);

const Column = styled(Col)(
  ({ theme }) => css`
    margin-bottom: var(--cui-spacings-zetta);

    ${theme.mq.mega} {
      margin-bottom: 140px;
    }
  `,
);

const ColumnHeadline = styled(Headline)`
  margin-bottom: var(--cui-spacings-mega);
`;

const ColumnContent = styled(Body)`
  margin-bottom: var(--cui-spacings-mega);
`;

export const SectionColumn: FC<Props> = ({ columnKey, columnFields }) => {
  const { orderDetails } = useTypedSelector((state) => state.order);
  const trackSectionCtaClick = (label: string): void => {
    void dispatchSkipShopEvent({
      label,
      orderId: orderDetails.id,
      merchantCode: localStorage.getItem(MERCHANT_ID),
    });
  };

  return (
    <Column
      key={columnKey}
      span={{ untilKilo: 12, kilo: 12, mega: 6 }}
      css={{ marginBottom: 'var(--cui-spacings-zetta)' }}
    >
      {columnFields.image && (
        <StyledImageWrapper>
          <Image
            src={convertImageToPng(columnFields.image.fields.file.url)}
            alt={columnFields.image.fields.description || ''}
          />
        </StyledImageWrapper>
      )}
      <ColumnHeadline as="h3">{columnFields.headline}</ColumnHeadline>
      {columnFields.content && (
        <ColumnContent>{columnFields.content}</ColumnContent>
      )}
      {columnFields.primaryCtaLabel && (
        <Button
          variant="primary"
          target="_blank"
          onClick={() =>
            trackSectionCtaClick(columnFields.primaryCtaTrackingId)
          }
          href={columnFields.primaryCtaUrl}
        >
          {columnFields.primaryCtaLabel}
        </Button>
      )}
      {columnFields.secondaryCtaLabel && (
        <Button
          variant="secondary"
          target="_blank"
          onClick={() =>
            trackSectionCtaClick(columnFields.secondaryCtaTrackingId)
          }
          css={{ marginLeft: 'var(--cui-spacings-kilo)' }}
          href={columnFields.secondaryCtaUrl}
        >
          {columnFields.secondaryCtaLabel}
        </Button>
      )}
    </Column>
  );
};
