import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import {
  findAppropriateCountryForUrl,
  findISOLocaleForCountry,
  readWindowPathnameWithDefaultLocale,
} from 'shared/i18n/helpers';
import useMerchantStore from 'shared/infra/identity/useMerchantStore';
import logger from 'shared/services/logger';
import { getChannelLink } from 'shared/utils/channel-link';

/**
 * Checks that the merchant (account country) matches the country the user is accessing
 */
const usePageMerchantCountryProtection = (isActive = true): void => {
  const router = useRouter();
  const { state } = useMerchantStore();
  const appMarket = findAppropriateCountryForUrl(
    readWindowPathnameWithDefaultLocale(),
  );
  const isoLocaleForMerchantCountry = findISOLocaleForCountry(
    state.merchantCountry,
  );
  const isPageProtectionActive = useMemo(() => {
    // only evaluate for authenticated users
    if (!state.isAuthenticated) {
      return false;
    }

    const mismatchEncountered =
      appMarket && state.merchantCountry && appMarket !== state.merchantCountry;

    if (mismatchEncountered && !isoLocaleForMerchantCountry) {
      logger.error(
        new Error(
          'Merchant Country Route Protection - locale for redirection not found',
        ),
        '',
        {
          tags: {
            appMarket,
            merchantCountry: state.merchantCountry,
            isoLocaleForMerchantCountry,
          },
        },
      );
    }

    return isActive && mismatchEncountered && isoLocaleForMerchantCountry;
  }, [isActive, appMarket, isoLocaleForMerchantCountry, state]);

  useEffect(() => {
    if (isPageProtectionActive) {
      void router.replace(
        {
          pathname: getChannelLink('/product-selection'),
          query: { ...router.query, switched_country: state.merchantCountry },
        },
        undefined,
        { locale: isoLocaleForMerchantCountry },
      );
    }
  }, [
    router,
    isPageProtectionActive,
    isoLocaleForMerchantCountry,
    state.merchantCountry,
  ]);
};

export default usePageMerchantCountryProtection;
