import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Truck } from '@sumup/icons';
import { useEffect, useState, type FC } from 'react';

import { getUserAgent } from 'shared/utils/user-agent';

interface BlackHeaderBarProps {
  copy: string;
}

const StyledWrapper = styled.div(
  ({ theme }) => css`
    align-items: center;
    background-color: var(--cui-bg-strong);
    color: var(--cui-fg-on-strong);
    display: flex;
    font-size: var(--cui-typography-body-one-font-size);
    height: 52px;
    justify-content: center;
    line-height: var(--cui-typography-body-one-line-height);
    overflow: hidden;
    width: 100vw;

    ${theme.mq.untilKilo} {
      font-size: var(--cui-typography-body-two-font-size);
      line-height: var(--cui-typography-body-two-line-height);
    }
  `,
);

const StyledMarquee = styled.div`
  align-items: center;
  animation: scroll-left 20s linear infinite;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  @keyframes scroll-left {
    0% {
      transform: translate(100vw, 0);
    }
    100% {
      transform: translate(-100vw, 0);
    }
  }
`;

const StyledTruck = styled(Truck)`
  margin-right: 10px;
`;

const BlackHeaderContent: FC<Pick<BlackHeaderBarProps, 'copy'>> = ({
  copy,
}) => (
  <>
    <StyledTruck width={16} />
    <p>{copy}</p>
  </>
);

export const BlackHeaderBar: FC<BlackHeaderBarProps> = ({ copy }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const { isMobile: isMobileOnUserAgent } = getUserAgent();
    setIsMobile(isMobileOnUserAgent);
  }, []);

  const hasAnimation = isMobile && copy.length >= 50;

  return (
    <StyledWrapper>
      {hasAnimation ? (
        <StyledMarquee>
          <BlackHeaderContent copy={copy} />
        </StyledMarquee>
      ) : (
        <BlackHeaderContent copy={copy} />
      )}
    </StyledWrapper>
  );
};
