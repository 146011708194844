import { css, type SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import type { Theme } from '@sumup/design-tokens';

type Props = {
  isHighlighted?: boolean;
  theme?: Theme;
};

const base = ({ isHighlighted }: Props): SerializedStyles => css`
  background-color: var(--cui-bg-subtle);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--cui-border-width-mega) solid transparent;
  border-top-left-radius: var(--cui-border-radius-mega);
  border-top-right-radius: var(--cui-border-radius-mega);
  border-bottom: none;

  ${isHighlighted && 'border-color: var(--cui-border-accent);'}
`;

export const ImageWrapper = styled.div<Props>(base);
