import styled from '@emotion/styled';
import { Spinner } from '@sumup/circuit-ui';
import type { FC } from 'react';

interface Props {
  className?: string;
}

const LoaderWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
`;

export const PageLoader: FC<Props> = ({ className }) => (
  <LoaderWrapper data-testid="spinner-test-id" className={className}>
    <Spinner size="l" />
  </LoaderWrapper>
);
