import { AuthState, useAuth } from '@sumup/react-nanoauth';
import { useMemo } from 'react';

import type { SSOClassicIdToken } from 'src/types/sso';

type MerchantState = {
  isAuthenticated: boolean;
  merchantCode?: string;
  merchantCountry?: string;
};

export type MerchantStore = {
  state: MerchantState;
};

const DEFAULT_STATE: MerchantState = {
  isAuthenticated: false,
  merchantCode: null,
  merchantCountry: null,
};

const useMerchantStore = (): MerchantStore => {
  const { idToken, authState } = useAuth();
  const isAuthenticated = authState === AuthState.Authenticated;
  const tokenInfo = idToken?.classic as unknown as SSOClassicIdToken;
  const merchantCode = tokenInfo?.merchant_code;
  const merchantCountry = tokenInfo?.merchant_country;

  const state = useMemo(
    () => ({
      ...DEFAULT_STATE,
      isAuthenticated,
      merchantCode,
      merchantCountry,
    }),
    [isAuthenticated, merchantCode, merchantCountry],
  );

  return {
    state,
  };
};

export default useMerchantStore;
