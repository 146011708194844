import { AuthState, useAuth } from '@sumup/react-nanoauth';
import { useEffect, useState, type FC, type PropsWithChildren } from 'react';

import { PageLoader } from 'shared/components/PageLoader';
import { getOIDCGuestToken } from 'shared/infra/account/services/token';
import {
  ejectInterceptor,
  injectOIDCGuestTokenInterceptor,
  injectOIDCUserTokenInterceptor,
} from 'shared/infra/http';

export const SessionProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getAccessToken: getOIDCUserToken, authState } = useAuth();
  const [isSessionReady, setIsSessionReady] = useState(false);

  useEffect(() => {
    let oidcTokenInterceptorId: number;

    switch (authState) {
      case AuthState.Initializing:
        setIsSessionReady(false);
        break;
      case AuthState.Unauthenticated:
        oidcTokenInterceptorId =
          injectOIDCGuestTokenInterceptor(getOIDCGuestToken);
        setIsSessionReady(true);
        break;
      case AuthState.Authenticated:
        oidcTokenInterceptorId = injectOIDCUserTokenInterceptor(
          getOIDCUserToken,
          authState,
        );
        setIsSessionReady(true);
        break;
      default:
    }

    return () => {
      ejectInterceptor(oidcTokenInterceptorId);
    };
  }, [authState, getOIDCUserToken]);

  return isSessionReady ? <>{children}</> : <PageLoader />;
};
