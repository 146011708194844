import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Headline } from '@sumup/circuit-ui';
import type { FC } from 'react';

type Props = {
  title: string;
  hasPageSections?: boolean;
};

const StyledHeadline = styled(Headline, {
  shouldForwardProp: (propName) => propName !== 'hasPageSections',
})<{ hasPageSections?: boolean }>(
  ({ theme, hasPageSections }) => css`
    margin-bottom: var(--cui-spacings-tera);
    text-align: center;

    ${theme.mq.giga} {
      font-size: ${hasPageSections ? '96px' : '64px'};
      line-height: ${hasPageSections ? '96px' : 'var(--cui-spacings-zetta)'};
      margin-top: var(--cui-spacings-peta);
      margin-bottom: ${hasPageSections ? '74px' : 'var(--cui-spacings-zetta)'};
    }
  `,
);

export const ProductSelectionPageTitle: FC<Props> = ({
  title,
  hasPageSections,
}) => (
  <StyledHeadline as="h1" hasPageSections={hasPageSections}>
    {title}
  </StyledHeadline>
);
